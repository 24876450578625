import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';

export enum RedirectService {
  Wizard = 'wizard',
  External = 'external',
}

export enum WindowTarget {
  Self = '_self',
  Blank = '_blank',
}

export const useRedirect = (
  url: string,
  service?: RedirectService,
  partnerName?: string,
  target?: WindowTarget,
) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiDomain
          }
        }
      }
    `,
  );

  return () => {
    axios.post(`//${site.siteMetadata.apiDomain}/api/log-redirection`, {
      url,
      service,
      partnerName,
    });
    window?.open(url, target ?? WindowTarget.Self)?.focus();
  };
};
