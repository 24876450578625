import { Button, respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const HeroSection = styled.section({
  background: 'linear-gradient(0deg, #00b7fd, #0078fd)',
  overflow: 'hidden',
  margin: 0,
  '.details': {
    zIndex: 2,
    padding: 'auto',
    [respondTo(undefined, 'md')]: {
      textAlign: 'center',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '2em',
      paddingBottom: '2em',
    },
    [respondTo('md')]: {
      paddingTop: 100,
      paddingBottom: 100,
      marginRight: 100,
    },
    h1: {
      maxWidth: 400,
      color: 'white',
      marginBottom: 50,
    },
    p: {
      maxWidth: 460,
      fontSize: 18,
      color: 'white',
      marginBottom: 30,
    },
  },
  '.hero-image': {
    margin: 'auto',
    img: {
      maxHeight: 400,
      maxWidth: '100%',
      [respondTo('md')]: {
        maxHeight: 540,
        maxWidth: 540,
        margin: '-50px -200px',
      },
    },
  },
});

export const Hero = ({ onQuote }) => {
  const heroImage = 'https://branding.123seguro.com/images/img_loggi_baul.png';

  return (
    <HeroSection>
      <section className="row container">
        <div className="details">
          <h1>O seguro que cabe no seu bolso</h1>
          <p>
            Garanta o seguro ideal para sua moto ou carro de forma rápida e descomplicada, sem
            burocracia. Feito especialmente para você, entregador!
          </p>
          <Button variant="secondary" onClick={onQuote}>
            Faça sua cotação
          </Button>
        </div>
        <figure className="hero-image">
          <img alt="bau loggi" src={heroImage} />
        </figure>
      </section>
    </HeroSection>
  );
};
