import { colors, respondTo, rowDistribution, subtitleFont, titleFont } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';
import { useEnvPrefix } from '../../../../hooks/use-env-prefix';
import { RedirectService, useRedirect } from '../../../../hooks/use-redirect';
import Banner from './Banner';
import Coverages from './Coverages/Coverages';
import { Description } from './Description';
import { Header } from './Header';
import { Hero } from './Hero';
import Footer from './footer';

const Partner = styled.article({
  padding: '0',
  '.container': {
    [respondTo(undefined, 'md')]: {
      maxWidth: 'calc(100% - 2em)',
    },
    [respondTo('md')]: {
      maxWidth: 730,
    },
    [respondTo('lg')]: {
      maxWidth: 900,
    },
    [respondTo('xl')]: {
      maxWidth: 1100,
    },
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
  },
  '.cnt-logo': {
    margin: '0 -16px 32px -16px',
    padding: 8,
    img: { height: 60, margin: '0 auto', display: 'block' },
  },
  '.row-headline, .row-introduction, .row-content': {
    ...rowDistribution('around'),
    'strong, em, a': { fontWeight: 'inherit', fontStyle: 'inherit', color: 'inherit' },
  },
  '.link-back span': {
    ...subtitleFont('md'),
    margin: '0 0 0.5em 8px',
    textTransform: 'uppercase',
  },
  h1: {
    ...titleFont('lg', 'serif'),
    marginBottom: '0.5em',
    color: colors.gray.darkest,
  },
  h2: {
    ...titleFont('md', 'serif'),
    marginBottom: '0.5em',
    color: colors.gray.darkest,
  },
  h3: {
    ...titleFont('xs', 'sans'),
    marginBottom: '0.5em',
    color: colors.gray.darkest,
  },
  h4: {
    marginBottom: '0.5em',
    color: colors.gray.darkest,
    fontSize: 18,
    fontWeight: 500,
  },
  '.row': {
    display: 'flex',
    boxSizing: 'border-box',
    flex: ' 0 1 auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  [respondTo('lg')]: {},
});

const PartnerPage = () => {
  const envPrefix = useEnvPrefix();
  const quoteUrl = `https://${envPrefix}123seguro.com.br/partner/loggi/carro/cotar/1`;
  const redirectToWizard = useRedirect(quoteUrl, RedirectService.Wizard, 'loggi');

  return (
    <Partner>
      <Header />
      <Hero onQuote={redirectToWizard} />
      <Description />
      <Coverages />
      <Banner onQuote={redirectToWizard} />
      <Footer onQuote={redirectToWizard} />
    </Partner>
  );
};

export default PartnerPage;
