import { Icon, colorTheme, icons, respondTo } from '@123-front/ui-kit';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import styled from '@emotion/styled';
import { useState } from 'react';

import React from 'react';

const CoveragesSection = styled.section({
  [respondTo('md')]: {
    display: 'none',
  },
  '.details': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 70,
    paddingBottom: 30,
    h2: {
      fontSize: 30,
      fontWeight: 700,
      fontFamily: 'Nunito Sans, sans-serif',
      maxWidth: 300,
    },
    p: {
      fontSize: 20,
      maxWidth: 300,
    },
  },
  '.coverage-card': {
    border: '2px solid #DDD',
    borderRadius: 15,
    padding: '20px 20px 40px 20px',
    maxWidth: 400,
    h4: {
      fontSize: 18,
      textAlign: 'center',
      color: '#003E32',
      height: '3em',
    },
    '.header-logo': {
      margin: 0,
      marginBottom: 20,
      textAlign: 'center',
    },
    hr: {
      marginTop: 20,
      marginBottom: 40,
      border: '1px solid #eee',
    },
    p: {
      fontSize: 16,
    },
  },
  '.dots': {
    paddingTop: 20,
    paddingBottom: 55,
  },
});

const CoverageCard = ({ name, details, logo }) => (
  <div className="coverage-card">
    <figure className="header-logo">
      <img alt={name} src={logo} height={50} />
    </figure>
    <h4>{name}</h4>
    <hr />
    {Object.keys(details).map((name) => (
      <p>
        <strong>{name}</strong> {details[name]}
      </p>
    ))}
  </div>
);

export const MobileCoverages = ({ coverages }) => {
  const suhaiLogo = 'https://branding.123seguro.com/logotypes/insurers/color/seguros-suhai.svg';
  const [index, setIndex] = useState(0);

  const slides: JSX.Element[] = Object.keys(coverages).map((coverage) => (
    <CoverageCard name={coverage} details={coverages[coverage]} logo={suhaiLogo} />
  ));

  const nextSlide = () => {
    setIndex(index + 1);
  };

  const prevSlide = () => {
    setIndex(index - 1);
  };

  return (
    <CoveragesSection className="container">
      <div className="details">
        <h2>Planos sob medida para você</h2>
        <p>Proteja seu veículo da maneira que deseja</p>
      </div>

      <Carousel
        animationSpeed={500}
        autoPlay={3000}
        stopAutoPlayOnHover={true}
        infinite={true}
        arrowLeft={
          <div onClick={prevSlide}>
            <Icon icon={icons.chevronLeft} size="30" color={colorTheme.interactive} />
          </div>
        }
        arrowRight={
          <div onClick={nextSlide}>
            <Icon icon={icons.chevronRight} size="30" color={colorTheme.interactive} />
          </div>
        }
        centered
        slides={slides}
        value={index}
        onChange={setIndex}
      />
      <div className="dots">
        <Dots value={index} number={slides.length} onChange={setIndex} />
      </div>
    </CoveragesSection>
  );
};
