import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const FooterMenuNav = styled.nav({
  paddingTop: 8,
  marginBottom: 40,
  '.option-grid': {
    [respondTo(undefined, 'md')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 20,
    },
    [respondTo('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      width: '100%',
    },
  },
  h4: {
    fontSize: 13,
    textTransform: 'uppercase',
  },
  [respondTo('lg')]: {
    paddingBottom: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '.container-flex': {
      flexDirection: 'row',
    },
  },
});

const FooterMenu = ({ options }) => {
  return (
    <FooterMenuNav>
      <div className="option-grid">
        {options.map(({ text, url, onClick }) => (
          <a href={url} onClick={onClick}>
            <h4>{text}</h4>
          </a>
        ))}
      </div>
    </FooterMenuNav>
  );
};

export default FooterMenu;
