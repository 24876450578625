import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';

import React from 'react';

const CoveragesSection = styled.section({
  display: 'grid',
  [respondTo(undefined, 'md')]: {
    display: 'none',
  },
  justifyContent: 'center',
  gap: 20,
  [respondTo('lg')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  padding: '50px 0',
  '.details': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    h2: {
      fontSize: 28,
      fontWeight: 700,
      fontFamily: 'Nunito Sans, sans-serif',
    },
  },
  '.coverage-card': {
    border: '2px solid #DDD',
    borderRadius: 15,
    padding: '20px 20px 40px 20px',
    maxWidth: 400,
    h4: {
      fontSize: 18,
      textAlign: 'center',
      color: '#003E32',
      height: '3em',
    },
    '.header-logo': {
      margin: 0,
      marginBottom: 20,
      textAlign: 'center',
    },
    hr: {
      marginTop: 20,
      marginBottom: 40,
      border: '1px solid #eee',
    },
    p: {
      fontSize: 13,
    },
  },
});

const CoverageCard = ({ name, details, logo }) => (
  <div className="coverage-card">
    <figure className="header-logo">
      <img alt={name} src={logo} height={50} />
    </figure>
    <h4>{name}</h4>
    <hr />
    {Object.keys(details).map((name) => (
      <p>
        <strong>{name}</strong> {details[name]}
      </p>
    ))}
  </div>
);

export const DesktopCoverages = ({ coverages }) => {
  const suhaiLogo = 'https://branding.123seguro.com/logotypes/insurers/color/seguros-suhai.svg';

  return (
    <CoveragesSection className="container">
      <div className="details">
        <h2>Planos sob medida para você</h2>
        <p>Proteja seu veículo da maneira que deseja</p>
      </div>
      {Object.keys(coverages).map((coverage) => (
        <CoverageCard name={coverage} details={coverages[coverage]} logo={suhaiLogo} />
      ))}
    </CoveragesSection>
  );
};
