import { bodyFont, box, colorTheme, Divider, Icon, icons, respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const FooterContactAddress = styled.address({
  position: 'relative',
  ...box,
  padding: '12px 16px',
  fontStyle: 'normal',

  ul: {
    ...bodyFont('sm'),
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    [respondTo('lg')]: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      width: 'auto',
    },
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    [respondTo('lg')]: {
      padding: '4px 12px',
    },
  },
  '.cnt-icon': {
    width: 24,
    marginRight: 8,
  },
  '.slash': {
    margin: '0 8px',
  },
});

const cleanPhone = (phone) => phone.replace(/-|\+| /g, '');
const makeWhatsappLink = (phone, text) =>
  `https://wa.me/${cleanPhone(phone)}` + (text ? `?text=${encodeURI(text)}` : '');

const contactDetails = {
  whatsAppPhone: '+55 11 94586-1008',
  whatsAppText:
    'Olá! Gostaria de gerar uma cotação para o meu veiculo na web da 123Seguro. Entrei pela loggi. Gostaria de conhecer as coberturas que tem para mim. Muito Obrigado!',
  telephone: '(11) 3195-7527',
  telephone2: '0800-888-1105',
  address: 'Avenida Antônio Batuira, 447 (Alto de Pinheiros, São Paulo)',
};

const FooterContact = ({}) => {
  return (
    <FooterContactAddress>
      <ul>
        <li>
          <span className="cnt-icon">
            <Icon icon={icons.whatsapp} color={colorTheme.mute} size="md" />
          </span>
          <a href={makeWhatsappLink(contactDetails.whatsAppPhone, contactDetails.whatsAppText)}>
            {contactDetails.whatsAppPhone}
          </a>
        </li>
        <li>
          <span className="cnt-icon">
            <Icon icon={icons.headset} color={colorTheme.mute} size="md" />
          </span>

          <a href={`tel:${contactDetails.telephone}`}>{contactDetails.telephone}</a>
          <span className="slash">/</span>
          <a href={`tel:${contactDetails.telephone2}`}>{contactDetails.telephone2}</a>
        </li>
        <Divider />
        <li>
          <span className="cnt-icon">
            <Icon className="icon" icon={icons.address} color={colorTheme.mute} size="md" />
          </span>
          <span>{contactDetails.address}</span>
        </li>
      </ul>
    </FooterContactAddress>
  );
};

export default FooterContact;
