import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const HeaderSection = styled.header({
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 0,
  '.header-logo': {
    margin: '0 20px',
  },
  'figure:only-child': {
    // center logo if only 1
    margin: '0 auto',
  },

  height: 69,
  'img.loggi': {
    height: 60,
  },
  'img.suhai': {
    height: 30,
  },

  [respondTo(undefined, 'sm')]: {
    height: 40,
    'img.loggi': {
      height: 36,
    },
    'img.suhai': {
      height: 18,
    },
  },
});

export const Header = ({}) => {
  const logos = {
    loggi: 'https://branding.123seguro.com/logotypes/partners/color/logo_loggi.svg',
    suhai: 'https://branding.123seguro.com/logotypes/insurers/color/seguros-suhai-solo.svg',
  };

  return (
    <HeaderSection className="row">
      <figure className="header-logo">
        <img alt="loggi" src={logos.loggi} className="loggi" />
      </figure>{' '}
      <figure className="header-logo">
        <img alt="suhai" src={logos.suhai} className="suhai" />
      </figure>
    </HeaderSection>
  );
};
