import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const FooterTopDiv = styled.div({
  marginBottom: '-16px',
  '.logo': {
    height: 60,
  },
  [respondTo(undefined, 'md')]: {
    textAlign: 'center',
  },
});

type Props = {
  logo: {
    src: string;
    alt: string;
    title?: string;
  };
};

const FooterTop = ({ logo }: Props) => {
  return (
    <FooterTopDiv>
      <img className="logo" {...logo} />
    </FooterTopDiv>
  );
};

export default FooterTop;
