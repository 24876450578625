import { colors, Column, Container, Divider, respondTo, Row } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';
import FooterContact from './FooterContact';
import FooterLegal from './FooterLegal';
import FooterMenu from './FooterMenu';
import FooterTop from './FooterTop';

const FooterContainer = styled.footer({
  padding: '16px 16px 24px 16px',
  backgroundColor: colors.gray.ultralight,
  borderTop: `1px solid ${colors.gray.lighten}`,
  [respondTo('lg')]: {
    padding: '16px 24px 32px 24px',
  },
});

const Footer = ({ onQuote }) => {
  const loggiLogo = {
    alt: 'Loggi',
    src: 'https://branding.123seguro.com/logotypes/partners/color/logo_loggi.svg',
  };

  const options = [
    {
      text: 'Faça sua cotação',
      onClick: onQuote 
    },
    {
      text: 'ASSISTENCIA 24H',
      url:
        'https://suhaiseguradora.com/wp-content/uploads/2023/11/CG-ASSISTENCIA-SUHAI-vigente-desde-Fevereiro-2022.pdf',
    },
    {
      text: 'Térmos e condições',
      url:
        'https://suhaiseguradora.com/wp-content/uploads/2023/11/CG-SUHAI-vigente-desde-Fevereiro-2022.pdf',
    },
  ];

  return (
    <FooterContainer>
      <Container>
        <Row internal={false}>
          <Column mobile={12} desktop={12}>
            <FooterTop logo={loggiLogo} />
            <Divider />
            <FooterMenu options={options} />
            <FooterContact />
            <FooterLegal />
          </Column>
        </Row>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
