import { Button, bodyFont, box, colors, respondTo, titleFont } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const BannerSection = styled.section({
  margin: 0,
  textAlign: 'center',
  position: 'relative',
  padding: '140px 16px 70px 16px',
  backgroundColor: `${colors.blue.lightest}`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionY: 'bottom',
  backgroundPositionX: 'center',
  [respondTo('md')]: {
    backgroundImage: `url(https://branding.s3.us-west-2.amazonaws.com/shapes/shape-curve-bottom-blue-large.svg)`,
    borderRadius: 4,
  },
  [respondTo('xs', 'md')]: {
    backgroundImage: `url(https://branding.s3.us-west-2.amazonaws.com/shapes/shape_curve-bottom-blue.svg)`,
  },
  '.banner-title': {
    ...titleFont('md', 'serif'),
    color: colors.gray.darkest,
    margin: 'auto',
    marginBottom: '0.7em',
    maxWidth: 900,
  },
  '.banner-description': {
    ...bodyFont('lg'),
    margin: 'auto',
    marginBottom: 40,
    maxWidth: 830,
  },
  [respondTo('lg')]: {
    margin: 0,
    '.cnt-banner': {
      backgroundSize: '100% 50%',
      borderRadius: 4,
    },
    '.banner-description': {
      marginBottom: 60,
    },
  },
});

const Logo = () => (
  <div
    style={{
      ...box,
      position: 'absolute',
      top: -30,
      left: '50%',
      marginLeft: -70,
      width: 140,
      height: 140,
      borderRadius: '50%',
      border: 'none',
    }}
  >
    <img
      style={{ height: 70, marginTop: 35, marginLeft: 6 }}
      src="https://branding.s3.us-west-2.amazonaws.com/logotypes/123/color/logo_123.svg"
      alt="123Seguro"
    />
  </div>
);

const Banner = ({ onQuote }) => {
  return (
    <BannerSection>
      <Logo />
      <p className="banner-description">
        Somos uma{' '}
        <strong>plataforma de distribuição de seguros digitais pioneira na América Latina</strong>
        .<br />
        Nascemos em 2010 com o objetivo de tornar a contratação de{' '}
        <strong>seguros transparente</strong>. Assessoramos mais de um milhão de pessoas em
        diferentes partes de todo o continente
      </p>
      <h3 className="banner-title">
        Proteja o seu veículo com descontos exclusivos para quem é entregador parceiro Loggi.
      </h3>
      <div className="banner-action">
        <Button variant="primary" size="lg" onClick={onQuote}>
          Faça sua cotação
        </Button>
      </div>
    </BannerSection>
  );
};

export default Banner;
