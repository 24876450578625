import { respondTo } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const DescriptionSection = styled.section({
  backgroundColor: '#006AFF',
  paddingTop: 70,
  paddingBottom: 70,
  color: 'white',
  '.header': {
    'h2, p': {
      textAlign: 'center',
      color: 'white',
    },
    p: {
      maxWidth: 800,
      fontSize: 18,
      margin: 'auto',
      marginBottom: 5,
    },
    marginBottom: 30,
  },
  '.partners': {
    display: 'flex',
    gap: 50,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [respondTo(undefined, 'md')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      marginTop: 50,
    },
  },
  '.company-card': {
    '.partner-logo': {
      margin: 0,
      marginBottom: 20,
      height: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.company-desc': {
      maxWidth: 480,
      minHeight: 250,
      backgroundColor: 'white',
      color: 'black',
      padding: '30px 20px',
      borderRadius: 15,
      alignContent: 'center',
      fontWeight: 200,
    },
    '.loggi-logo': {
      paddingBottom: 20,
    },
  },
});

export const Description = () => {
  const loggiLogo = 'https://branding.123seguro.com/logotypes/partners/white/logo_loggi.svg';
  const suhaiLogo =
    'https://branding.123seguro.com/logotypes/insurers/white/seguros-suhai-solo.svg';
  return (
    <DescriptionSection>
      <div className="header container">
        <h2>Seguro é uma questão de escolha!</h2>
        <p>
          O Clube de Vantagens da Loggi em parceria com a Suhai Seguradora se uniu para oferecer um
          seguro que cabe no seu bolso.
        </p>
      </div>
      <div className="partners container">
        <div className="company-card">
          <figure className="partner-logo loggi-logo">
            <img alt={'loggi-logo'} src={loggiLogo} height={70} />
          </figure>
          <div className="company-desc">
            Uma empresa que usa a <strong>tecnologia para inovar</strong> os envios de pacotes para
            todo o Brasil com praticidade e no <strong>tempo que você precisa</strong>. Por aqui, a
            gente acredita que uma <strong>logística</strong> de <strong>qualidade</strong> é um
            direito de todas as pessoas.
            <br />
            Presente em <strong>todas</strong> as capitais brasileiras, movimentamos mais de{' '}
            <strong>300 mil</strong> pacotes por dia. Um serviço de logística que usa{' '}
            <strong>tecnologia</strong> para enviar pacotes com mais <strong>agilidade</strong> e de
            forma <strong>prática</strong>. Para perto ou para longe, vem com a Loggi.
          </div>
        </div>
        <div className="company-card">
          <figure className="partner-logo">
            <img alt={'suhai-logo'} src={suhaiLogo} height={80} />
          </figure>
          <div className="company-desc">
            Você bem-vindo, seu <strong>bem seguro</strong>. Uma empresa brasileira{' '}
            <strong>líder em seguro de moto</strong>, com mais de 11 anos no mercado. A Suhai surgiu
            para atender clientes que não tem aceitação para seus veículos nas seguradoras
            tradicionais.
            <br />
            Somos uma <strong>seguradora devidamente regularizada</strong> e regulamentada pela
            SUSEP, o órgäo do governo responsável por fiscalizar as empresas de seguro do país.
            <br />
            Achou que não podia ou não precisava ter um seguro?
            <br />
            <strong>Na Suhai, ter seguro é uma escolha.</strong>
          </div>
        </div>
      </div>
    </DescriptionSection>
  );
};
