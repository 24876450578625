import React, { Fragment } from 'react';
import { DesktopCoverages } from './DesktopCoverages';
import { MobileCoverages } from './MobileCoverages';

const Coverages = () => {
  const baseCoverage = {
    'Valor Mercado': '100% FIPE ou porcentagem aceita pela Seguradora',
    'Danos a Terceiros - Materiais': 'Sem cobertura',
    'Danos a Terceiros - Corporais': 'Sem cobertura',
    'Danos a Terceiros - Morais': 'Sem cobertura',
    'Assistência 24h': 'Reboque (Guincho) 200 km Até 3 por serviço durante a vigência do Seguro',
  };
  const coverages = {
    'Cobertura Roubo e Furto': baseCoverage,
    'Cobertura Roubo e Furto  + Perda Total  Colisão': baseCoverage,
    'Cobertura Roubo e Furto  + Perda Total  Colisão + RCF': {
      ...baseCoverage,
      'Danos a Terceiros - Materiais': 'R$50.000',
      'Danos a Terceiros - Corporais': 'R$50.000',
    },
  };

  return (
    <Fragment>
      <DesktopCoverages coverages={coverages} />
      <MobileCoverages coverages={coverages} />
    </Fragment>
  );
};

export default Coverages;
