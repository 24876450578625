import { colorTheme } from '@123-front/ui-kit';
import styled from '@emotion/styled';
import React from 'react';

const FooterLegalDiv = styled.div({
  textAlign: 'center',
  '.juridical-logos': {
    margin: '28px 0 12px 0',
    img: {
      height: 28,
      margin: '4px 16px',
      maxWidth: '90%',
    },
    '.parceiro-img': {
      height: 60,
      margin: '-15px 16px',
    },
  },
  '.legal-text': {
    fontSize: 10,
    lineHeight: 1,
    color: colorTheme.mute,
  },
});

const FooterLegal = ({}) => {
  const legalImages = [
    {
      source: 'https://branding.123seguro.com/logotypes/footer/logo_sincor.png',
      name: 'Sincor',
    },
    {
      source: 'https://branding.123seguro.com/logotypes/footer/logo_susep.svg',
      name: 'SUSEP',
    },
  ];
  const parceiroImg = {
    source: 'https://branding.123seguro.com/images/img_pareceiro_loggi.png',
    name: 'Parceiro Autorizado Suhai',
  };

  return (
    <FooterLegalDiv>
      <div className="juridical-logos">
        {Array.isArray(legalImages) &&
          legalImages.map((image) => <img key={image.name} src={image.source} alt={image.name} />)}
        <img
          key={parceiroImg.name}
          src={parceiroImg.source}
          alt={parceiroImg.name}
          className="parceiro-img"
        />
      </div>
      <div className="legal-text">
        <p>
          A Seguro.com.vc Corretora de Seguros Ltda atua em território nacional e está sediada na
          Rua Butantã, 468 (Alto de Pinheiros, São Paulo) e inscrita no CNPJ nº 38.177.621/0001-18.
          Atuamos em estrita observância ao Código de Defesa do Consumidor e à Legislação
          Securitária, devidamente registrada na Superintendência de Seguros Privados - SUSEP - sob
          o n.º 1.020.270.658. "Seguro, só com o corretor de seguros".
        </p>
      </div>
    </FooterLegalDiv>
  );
};

export default FooterLegal;
