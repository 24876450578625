import { baseStyles, normalize } from '@123-front/ui-kit';
import '@brainhubeu/react-carousel/lib/style.css';
import { Global } from '@emotion/core';
import React, { Fragment } from 'react';
import PartnerDetails from '../../../components/partners/br/suhai-loggi';
import SEO from '../../../components/seo';

const LoggiLandingBR: React.FC = () => {
  return (
    <Fragment>
      <Global styles={[normalize, baseStyles]}></Global>
      <SEO title="Garanta o seguro ideal para sua moto ou carro de forma rápida e descomplicada, sem burocracia. Feito especialmente para você, entregador!" />
      <PartnerDetails />
    </Fragment>
  );
};

export default LoggiLandingBR;
