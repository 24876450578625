import { graphql, useStaticQuery } from 'gatsby';

export enum ENV_PREFIX {
  DEV = 'dev.',
  TEST = 'test.',
  UAT = 'uat.',
  PROD = '',
}

export const useEnvPrefix = (): ENV_PREFIX => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiDomain
          }
        }
      }
    `,
  );

  const domain = site.siteMetadata.apiDomain.replace(/(^\w+:|^)\/\//, '') || '';

  if (domain.startsWith('dev')) return ENV_PREFIX.DEV;
  if (domain.startsWith('test')) return ENV_PREFIX.TEST;
  if (domain.startsWith('uat')) return ENV_PREFIX.UAT;

  return ENV_PREFIX.PROD;
};
